import { Component, HostBinding } from '@angular/core';
import { AbstractDynamicComponent } from './abstract-dynamic.component';
import { Animations } from '../../animations/animations';

@Component({ template: '', animations: [Animations.popUp] })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractDynamicAnimatedPopup<DTA, RTN = unknown> extends AbstractDynamicComponent<DTA, RTN> {
    @HostBinding('@popUp')
    public hasPopupAnimation = true;
}
