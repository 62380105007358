import { Subject } from 'rxjs';

export abstract class AbstractDynamicComponent<DTA, RTN = unknown> {
    dataParams: DTA | undefined;
    public close$ = new Subject<RTN | undefined>();
    public close(result: RTN | undefined = undefined) {
        this.close$.next(result);
        this.close$.complete();
    }
}
