import { ChangeDetectorRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { DynamicContentRef } from './dynamic.content.ref';
import { AbstractDynamicComponent } from './abstract-dynamic.component';

@Injectable({
    providedIn: 'root',
})
export class DynamicContentService {
    private viewContainerRef!: ViewContainerRef;
    private changeDetectorRef!: ChangeDetectorRef;
    private dynamicContents: DynamicContentRef<unknown, unknown>[] = [];

    public setViewRefAndChangeDetectorRef(viewRef: ViewContainerRef, changeDetectorRef: ChangeDetectorRef) {
        if (!this.viewContainerRef) {
            this.viewContainerRef = viewRef;
            this.changeDetectorRef = changeDetectorRef;
        } else {
            throw new Error('Multiple containers are not supported.');
        }
    }

    public openContent<DTA, RET>(component: Type<AbstractDynamicComponent<DTA, RET>>, params?: DTA): DynamicContentRef<DTA, RET> {
        const componentRef = this.viewContainerRef.createComponent(component);
        componentRef.instance.dataParams = params;

        const dynamicContent = new DynamicContentRef<DTA, RET>(componentRef);
        dynamicContent.beforeClose.subscribe(() => this.removeDynamicContentFromLocalList<DTA, RET>(dynamicContent));
        this.dynamicContents.push(dynamicContent as DynamicContentRef<unknown, unknown>);
        try {
            this.changeDetectorRef.detectChanges();
        } catch (e) {
            console.log(e);
        }
        return dynamicContent;
    }

    public closeAllDialogs() {
        this.dynamicContents.forEach((dynamicContent) => dynamicContent.close());
        this.dynamicContents = [];
        this.changeDetectorRef.detectChanges();
    }

    private removeDynamicContentFromLocalList<DTA, RET>(dynamicContent: DynamicContentRef<DTA, RET>) {
        const index = this.dynamicContents.indexOf(dynamicContent as DynamicContentRef<unknown, unknown>);
        if (index === -1) {
            return;
        }
        const copy = [...this.dynamicContents];
        copy.splice(index, 1);
        this.dynamicContents = copy;
    }
}
